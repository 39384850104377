<template>
  <div class="main">
    <div class="merch-card scorr-roll">
      <template>
        <div class="top">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="预警记录" name="Warningrecor" />
            <el-tab-pane label="预警配置" name="Warniconfier" />
          </el-tabs>
        </div>
        <!-- 预警记录 -->
        <div v-show="activeName == 'Warningrecor'">
          <el-form ref="queryForm" :model="queryParams" :inline="true">
            <el-form-item prop="datelist" label="预警时间">
              <el-date-picker
                v-model="queryParams.datelist"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                unlink-panels
                value-format="yyyy-MM-dd HH:mm:ss"
              />
            </el-form-item>
            <el-form-item prop="orderNodeId" label="预警节点">
              <el-select
                v-model="queryParams.orderNodeId"
                placeholder="订单类型"
                clearable
                size="small"
              >
                <el-option
                  v-for="(item, index) in orderTypeList"
                  :key="index"
                  :label="item.orderNodeName"
                  :value="item.orderNodeId"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" :loading="loading" @click="handleQuery"
                >查询</el-button
              >
              <el-button type="primary" :loading="loading" @click="exportdown"
                >导出</el-button
              >
            </el-form-item>
          </el-form>
          <el-table
            v-loading="loading"
            :data="dataList"
            :header-cell-style="{ background: '#F2F3F5' }"
            border
          >
            <el-table-column
              align="center"
              label="序号"
              type="index"
              :index="indexMethod"
              width="70"
            ></el-table-column>
            <el-table-column prop="id" align="center" label="预警编号" />
            <el-table-column
              prop="orderNodeName"
              align="center"
              label="预警节点"
            />
            <el-table-column prop="orderId" align="center" label="订单号" />
            <el-table-column
              prop="timingNode"
              align="center"
              label="计时节点"
              label-class-name="red"
            >
              <template slot-scope="scope">
                <span class="col_F34444">{{ scope.row.timingNode }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="timingAt"
              align="center"
              label="计时开始时间"
            />
            <el-table-column
              prop="expectedTime"
              align="center"
              label="预计耗时（分）"
            />

            <el-table-column
              prop="orderAlertTime"
              align="center"
              label="预警时间"
            />
          </el-table>
          <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParams.page"
            :limit.sync="queryParams.perPage"
            @pagination="getList"
          />
        </div>
        <!-- 预警配置 -->
        <div v-show="activeName == 'Warniconfier'">
          <div class="disflex">
            <div class="el-icon-warning fx1" style="flex: 1">
              节点预计处理完成时间=【上一节点结束时间】+【预计耗时时间】+【期间的工作日休息时间】+【期间的非工作日时间】，当超时未处理将进行预警提醒
            </div>
            <div class="text-right">
              <el-button type="primary" @click="Batchsettings"
                >批量设置</el-button
              >
            </div>
          </div>
          <el-table
            v-loading="loading"
            :data="dataList"
            @selection-change="handleSelectionChange"
            :header-cell-style="{ background: '#F2F3F5' }"
            border
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
              v-if="dataList.length != 0"
              :selectable="selectEnable"
            />
            <el-table-column prop="id" align="center" label="序号" />
            <el-table-column
              prop="nodeNumber"
              align="center"
              label="节点编号"
            />
            <el-table-column prop="nodeName" align="center" label="节点名称" />
            <el-table-column
              prop="timingNode"
              align="center"
              label="计时节点"
              label-class-name="red"
            >
              <template slot-scope="scope">
                <span class="col_F34444">{{ scope.row.timingNode }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="nodeTimeSource"
              align="center"
              label="节点时间来源"
            />
            <el-table-column
              prop="expectedTime"
              align="center"
              label="预计耗时（分）"
            />
            <el-table-column prop="weekday" align="center" label="工作日" />
            <el-table-column prop="" align="center" label="休息时间">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.resetTimeStart }}-</span>
                  <span v-if="scope.row.resetTimeInterval == 1">次日</span>
                  <span>{{ scope.row.resetTimeEnd }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="更新人" />
            <el-table-column
              prop="updateTime"
              align="center"
              label="最后更新时间"
            />
            <el-table-column prop="" align="center" label="操作">
              <template slot-scope="scope">
                <span class="btsed" @click="editItem(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </div>
    <!-- 批量设置\节点编辑弹窗 -->
    <el-dialog
      :title="itemData.id != '' ? '节点编辑' : '批量设置'"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      v-if="dialogVisible"
    >
      <el-form
        ref="itemData"
        :model="itemData"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item
          label="节点编号"
          prop="nodeNumber"
          v-if="itemData.id != ''"
        >
          <span>{{ itemData.nodeNumber }}</span>
        </el-form-item>
        <el-form-item label="节点名称" prop="nodeName" v-if="itemData.id != ''">
          <span>{{ itemData.nodeName }}</span>
        </el-form-item>
        <el-form-item
          label="节点耗时"
          prop="expectedTime"
          v-if="itemData.id != ''"
        >
          <el-input-number
            v-model="itemData.expectedTime"
            controls-position="right"
            :min="1"
            :max="100000"
          ></el-input-number>
          <span class="marle_10">(分)</span>
        </el-form-item>
        <el-form-item label="工作日" prop="weekdayArr">
          <el-checkbox-group v-model="itemData.weekdayArr">
            <el-checkbox :label="'1'">周一</el-checkbox>
            <el-checkbox :label="'2'">周二</el-checkbox>
            <el-checkbox :label="'3'">周三</el-checkbox>
            <el-checkbox :label="'4'">周四</el-checkbox>
            <el-checkbox :label="'5'">周五</el-checkbox>
            <el-checkbox :label="'6'">周六</el-checkbox>
            <el-checkbox :label="'7'">周日</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="休息时间" prop="resetTimeStart">
          <el-time-picker
            value-format="HH:mm:ss"
            placeholder="起始时间"
            v-model="itemData.resetTimeStart"
            style="width: 120px"
          >
          </el-time-picker>
          <el-time-picker
            value-format="HH:mm:ss"
            placeholder="结束时间"
            v-model="itemData.resetTimeEnd"
            style="width: 120px; margin: 0px 10px"
            :picker-options="{
              selectableRange: startTime,
            }"
          >
          </el-time-picker>
          <el-checkbox
            v-model="itemData.resetTimeIntervalstype"
            @change="changterval"
            >次日</el-checkbox
          >
        </el-form-item>
        <div class="yellos">
          注意：时间修改后，只能影响后续的预计时间计算，已计算
          预警时间的节点还是会按原预计时间
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false"> 取 消 </el-button>
        <el-button @click="btnonsds" type="primary" :loading="loading">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const getOrder = createNamespacedHelpers("Order"); //vuex公共库
export default {
  name: "Aftersales",
  components: {},
  data() {
    return {
      activeName: "Warningrecor",
      queryParams: {
        page: 1,
        perPage: 10,
        datelist: [],
        startTime: "", //时间起始
        endTime: "", //时间截止
        orderNodeId: "", //预警节点
      },
      orderTypeList: [], //预警节点下拉数据
      dataList: [], //数据列表
      loading: false,
      total: 0, // 总条数
      ids: [], //多选框数据
      dialogVisible: false, //批量设置\节点编辑弹窗
      itemData: {
        id: "", //id
        nodeNumber: "", //节点编号
        nodeName: "", //节点名称
        expectedTime: "", //节点耗时
        weekdayArr: [], //工作日数组
        resetTimeStart: "", //开始时间
        resetTimeEnd: "", //结束时间
        resetTimeInterval: 0, //是否次日 0否 1是
        resetTimeIntervalstype: false, //是否次日
      },
      rules: {
        nodeNumber: [
          { required: true, message: "请设置节点编号", trigger: "blur" },
        ],
        nodeName: [
          { required: true, message: "请设置节点名称", trigger: "blur" },
        ],
        expectedTime: [
          { required: true, message: "请设置工作耗时", trigger: "change" },
        ],
        weekdayArr: [
          { required: true, message: "请选择工作日", trigger: "change" },
        ],
        resetTimeStart: [
          { required: true, message: "请选择休息时间", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    startTime() {
      if (!this.itemData.resetTimeIntervalstype) {
        return `${this.itemData.resetTimeStart} - "23:59:59"`;
      } else {
        return "00:00:00 - 23:59:59";
      }
    },
  },
  created() {
    this.nodeSelect().then((res) => {
      if (res.code == "200") {
        this.orderTypeList = res.data;
      }
    });
    this.getList();
  },
  methods: {
    ...getOrder.mapActions([
      "nodeSelect", //预警配置-预警节点下拉选择
      "orderAlertconfigList", //预警配置-配置列表
      "configDetail", //预警配置-详情
      "configEdit", //预警配置-编辑
      "batchEdit", //预警配置-批量编辑
      "searchList", //订单预警-列表
      "exportRecord", //订单预警-导出
    ]),
    //序号
    indexMethod(index) {
      return index + (this.queryParams.page - 1) * this.queryParams.perPage + 1;
    },
    /** 查询列表 */
    getList() {
      this.loading = true;
      if (this.activeName == "Warningrecor") {
        //预警记录
        var queryParams = { ...this.queryParams };
        if (queryParams.datelist) {
          queryParams.startTime = queryParams.datelist[0];
          queryParams.endTime = queryParams.datelist[1];
        } else {
          queryParams.startTime = "";
          queryParams.endTime = "";
        }
        this.searchList(queryParams).then((response) => {
          this.dataList = response.data.data;
          this.total = response.data.total;
          this.loading = false;
        });
      } else {
        //预警配置
        this.orderAlertconfigList().then((response) => {
          this.dataList = response.data.data;
          this.total = response.data.total;
          this.loading = false;
        });
      }
    },
    handleQuery() {
      this.queryParams.page = 1;
      this.getList();
    },
    async handleClick() {
      Object.assign(this.queryParams, this.$options.data().queryParams);
      await this.getList();
    },
    /** 多选框选中数据 */
    handleSelectionChange(selection) {
      this.ids = [];
      for (let i in selection) {
        this.ids.push(selection[i]);
      }
      //   this.batch = !selection.length;
    },
    /** 禁用部分多选列表 */
    selectEnable(row) {
      return true;
      //   if (
      //     row.supplementaryAmount > 0 &&
      //     row.balancePaymentStatus != 1 &&
      //     row.payment == 1
      //   ) {
      //     return true;
      //   }
    },
    /** 批量设置 */
    Batchsettings() {
      if (this.ids.length == 0)
        return this.$message.error("请选择批量设置数据");
      Object.assign(this.itemData, this.$options.data().itemData);
      this.dialogVisible = true;
    },
    /** 编辑 */
    editItem(row) {
      this.configDetail({ id: row.id }).then((res) => {
        Object.assign(this.itemData, res.data);
        row.resetTimeInterval == 1
          ? (this.itemData.resetTimeIntervalstype = true)
          : (this.itemData.resetTimeIntervalstype = false);
        this.dialogVisible = true;
      });
    },
    changterval(v) {
      this.itemData.resetTimeEnd = "";
      v
        ? (this.itemData.resetTimeInterval = 1)
        : (this.itemData.resetTimeInterval = 0);
    },
    /** 导出 */
    exportdown() {
      var queryParams = { ...this.queryParams };
      if (queryParams.datelist) {
        queryParams.startTime = queryParams.datelist[0];
        queryParams.endTime = queryParams.datelist[1];
      } else {
        queryParams.startTime = "";
        queryParams.endTime = "";
      }
      downLoadZip(
        `/order/orderAlertRecord/exportRecord`,
        "xlsx",
        "预警记录导出",
        queryParams,
        "php",
        "get"
      );
    },
    /** 批量设置\节点编辑 */
    async btnonsds() {
      this.$refs["itemData"].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          if (this.itemData.id != "") {
            await this.configEdit(this.itemData).then((res) => {
              if (res.code == "200") {
                this.$message.success("修改成功");
                this.loading = false;
                this.dialogVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            var itemData = {
              ...this.itemData,
              ids: [],
            };
            this.ids.forEach((v) => {
              itemData.ids.push(v.id);
            });
            await this.batchEdit(itemData).then((res) => {
              if (res.code == "200") {
                this.$message.success("批量设置成功");
                this.loading = false;
                this.dialogVisible = false;
              } else {
                this.$message.error(res.message);
              }
            });
          }
          this.getList();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    min-width: 1000px;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
  }
}
.disflex {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
</style>
